<template>
	<div class="h-screen flex flex-col items-stretch">
		<header class="flex-none w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div
						class="w-full flex justify-between items-center p-2 text-white"
					>
						<div class="text-white p-5">
							<WaveLogo
								class="cursor-pointer h-16 md:h-12 lg:h-16"
							/>
						</div>
						<div>
							<h1 class="text-2xl">Customer Check-In</h1>
						</div>
						<div>
							<p class="m-0">
								{{ new Date() | moment("dddd Do MMMM") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main class="flex-auto container mx-auto py-2 lg:p-8 lg:py-3">
			<section class="h-full flex flex-col justify-center items-center">
				<div class="text-sm text-center mb-10">
					<p class="font-bold mb-2">Discount code confirmation</p>
					<p class="mb-2">
						Please ask a member of The Wave team for assistance
					</p>
					<p v-if="surfer" class="mb-2">
						Promotional Code:
						{{ surfer.promotionCode }}
					</p>
				</div>
				<input
					type="text"
					style="
						text-security:disc;
						-webkit-text-security:disc;
						-mox-text-security:disc;
					"
					autocomplete="off"
					@input="checkPasscode"
					v-model="passcode"
					class="h-16 w-64 p-4 text-lg text-center focus:outline-none bg-gray-100 rounded-full"
					placeholder="Enter Passcode Here"
				/>
			</section>
		</main>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapActions } from "vuex";

	import WaveLogo from "@/components/WaveLogo.vue";

	export default {
		components: {
			WaveLogo
		},
		data() {
			return {
				verificationPassed: false,
				passcode: ""
			};
		},
		created() {
			// Active User Check
			if (this.searchSurfWaiversActiveUser === null) {
				this.$router.push({ name: "CheckIn" });
			}
		},
		computed: {
			...mapState("surfers", ["searchSurfWaiversActiveUser"]),
			verificationNeeded() {
				// Get array of Surfers that have a Local Pass Coupon
				//const surfersWithCoupons = this.pendingCheckIn().filter(surfer => surfer.coupon === "local-pass");

				// Other things that need verification
				// ...

				// If verification needed, return true, else retrun false
				//if (surfersWithCoupons.length > 0) return true;
				//return false;

				// !!! Doing checks on previous screen now please ignore above
				return true;
			}
		},
		methods: {
			...mapGetters({
				pendingCheckIn: "surfers/getPendingCheckIn"
			}),
			...mapActions(["surfers/staticCreateSurferCheckIn"]),
			checkPasscode() {
				if (
					this.passcode.length === 6 &&
					this.passcode === this.$moment().format("DDMMYY")
				) {
					this.verificationPassed = true;
					this.checkInSurfers();
				}
			},
			checkInSurfers() {
				// If we need verifiction and have yet to pass the check then return
				if (
					this.verificationNeeded === true &&
					this.verificationPassed !== true
				)
					return;

				// For all surfers that need to be checked in
				this.pendingCheckIn().forEach(surfer => {
					// Construct Object
					let data = {
						surferWaiverUUID: surfer.uuid,
						surfSiteLocationUUID:
							process.env
								.VUE_APP_WAVE_SURF_SITE_BRISTOL_ON_SITE_ID //On Site Location ID
					};

					// Store CheckIn
					this["surfers/staticCreateSurferCheckIn"](data);
				});

				// Check Disclaimer Parameter
				if (this.$route.query.showDisclaimer) {
					// Show Terms
					this.$router.push({ name: "CheckInTerms" });
				} else {
					// Complete
					this.$router.push({ name: "CheckInComplete" });
				}
			}
		}
	};
</script>
